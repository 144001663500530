import React, { FC, useContext } from "react"
import {
  ModalWrapper,
  HeaderLogo,
  FooterLogo,
  ContactInfo,
  ContactTitle,
  ContactItems,
} from "./index.styled"
import { Context } from "../../context/context"
import btn_close from "../../assets/images/button_close.svg"
import MTLogo from "../../assets/images/main_logo_1_dark.svg"
import MTLogoMob from "../../assets/images/main_logo_mob-dark.svg"
import HLogo from "../../assets/images/logo_hines.svg"
import CWLogo from "../../assets/images/logo_cushman_wakefield.svg"
import useWindowSize from "../../helpers/windowSize"
import iconFB from "../../assets/images/icon_fb.svg"
import iconInsta from "../../assets/images/icon_insta.svg"
import iconTw from "../../assets/images/icon_twitter.svg"
import iconIn from "../../assets/images/icon_in.svg"

const ContactModal: FC = () => {
  const { setOpenContact, setOpenLegal } = useContext(Context)
  const { width } = useWindowSize()
  return (
    <ModalWrapper className={"fade"}>
      <HeaderLogo>
        <img
          src={width > 577 ? MTLogo : MTLogoMob}
          alt="Maple Terrace Uptown Dallas"
        />
        <img
          src={btn_close}
          alt="Close button"
          onClick={() => setOpenContact(false)}
        />
      </HeaderLogo>

      <ContactInfo>
        <ContactTitle>
          <h2 className={"h1"}>We'll keep the</h2>
          <h2 className={"h1i"}>door open for you.</h2>
        </ContactTitle>
        <ContactItems>
          <div className="contact-item">
            <h4 className="h4">Matt Schendle</h4>
            <a href="mailto:matt.schendle@cushwake.com">
              matt.schendle@cushwake.com
            </a>
            <a href="tel:+19726639634">1.972.663.9634</a>
          </div>
          <div className="contact-item">
            <h4 className="h4">CARRIE HALBROOKS</h4>
            <a href="mailto:carrie.halbrooks@cushwake.com">
              carrie.halbrooks@cushwake.com
            </a>
            <a href="tel:+19726639887">1.972.663.9887</a>
          </div>
          {width < 577 && (
            <>
              <div className="contact-item">
                <h5 className="bolder">MAPLE TERRACE</h5>
                <h5>3001 Maple Ave, Dallas, TX 75201</h5>
              </div>
              <div className="socials">
                <a href="#" target={"_blank"}>
                  <img src={iconFB} alt="facebook icon" />
                </a>
                <a href="#" target={"_blank"}>
                  <img src={iconInsta} alt="instagram icon" />
                </a>
                <a href="#" target={"_blank"}>
                  <img src={iconTw} alt="twitter icon" />
                </a>
                <a href="#" target={"_blank"}>
                  <img src={iconIn} alt="linkedIn icon" />
                </a>
              </div>
              <div className="contact-item">
                <span className={"tt-link bolder"}>
                  For apartment leasing, please visit our{" "}
                  <a href="https://mapleterraceresidences.com/" target="_blank">
                    Maple Terrace Residence website
                  </a>
                  .
                </span>
              </div>
            </>
          )}
          {width > 1023 && (
            <div className="contact-item">
              <a
                className={"tt-link"}
                href="mailto:INFO@MAPLETERRACEOFFICES.COM"
              >
                INFO@MAPLETERRACEOFFICES.COM
              </a>
              <span
                className={"tt-link"}
                onClick={() => {
                  setOpenContact(false)
                  setOpenLegal(true)
                }}
              >
                LEGAL
              </span>
              <div className={"tt-link bolder"}>
                For apartment leasing, please visit our{" "}
                <a href="https://mapleterraceresidences.com/" target="_blank">
                  Maple Terrace Residence website
                </a>
                .
              </div>
            </div>
          )}
        </ContactItems>
      </ContactInfo>

      <FooterLogo>
        <div className={"logos-container"}>
          <a href="https://www.hines.com/" target={"_blank"}>
            <img src={HLogo} alt="Hines" />
          </a>
          <a href="https://www.cushmanwakefield.com/">
            <img src={CWLogo} alt="Cushman Wakefield" />
          </a>
        </div>
        {width < 577 && (
          <span
            onClick={() => {
              setOpenContact(false)
              setOpenLegal(true)
            }}
          >
            LEGAL
          </span>
        )}
      </FooterLogo>
    </ModalWrapper>
  )
}

export default ContactModal
